import { getConterPartyLogo, opportunityClosedStates } from "@helpers/constants";
import { getTenure, getNumberAsCurrency, getExpectedMaturityDate } from "@helpers/miscelleanous";
import { getRiskRatingName, getRiskCategoryName } from '@components/Investor/Opportunity/SecondLevelDetails/SecondLevelDetailsHelper';
import { OpportunityStateEnum } from '@helpers/enums/opportunity-enums';

const numberWithinRange = (number, min, max) => {
    const clampedNumber = Math.min(Math.max(number, min), max);
    return clampedNumber;
};

const formatHomePageDeals = (entity) => {
    try {
        const data = entity?.map((deal) => {
            let tenure = deal?.Opportunity?.tenure;
            const isClosed = opportunityClosedStates.includes(deal?.Opportunity?.state)
            if (deal?.Opportunity?.paymentSchedule.length > 0 && !isClosed) {
                const expectedMaturityDate = getExpectedMaturityDate(deal?.Opportunity);
                tenure = getTenure(null, expectedMaturityDate)
            }
            return {
                id: deal.id,
                opportunityId: deal?.Opportunity?.id,
                title: deal?.Opportunity?.title,
                description: deal?.Opportunity?.industryType?.name,
                status: deal?.Opportunity?.state === OpportunityStateEnum.INVESTMENT_READY ? 'LIVE NOW' : 'FULLY SUBSCRIBED',
                image: getConterPartyLogo(deal?.Opportunity),
                yield: deal?.Opportunity?.displayIRR ? `${deal?.Opportunity?.displayIRR}%` : '-',
                tenure: tenure,
                minInvestment: deal?.Opportunity?.minInvestmentAmount ? getNumberAsCurrency(Math.round(deal?.Opportunity?.minInvestmentAmount)) : '-',
                ratingCategory: deal?.Opportunity?.opportunityRiskRatings ? getRiskRatingName(deal?.Opportunity?.opportunityRiskRatings[0]) : '-',
                riskCategory: deal?.Opportunity?.opportunityRiskRatings ? getRiskCategoryName(deal?.Opportunity?.opportunityRiskRatings) : '-',
                canInvest: deal?.Opportunity?.state === OpportunityStateEnum.INVESTMENT_READY,
                isin: deal?.Opportunity?.isin
            };
        })
        return data;
    } catch (error) {
        return [];
    }
}

export { numberWithinRange, formatHomePageDeals };
