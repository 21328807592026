import { Text } from '@ui/atoms';
import {
    NextButton,
    PrevButton,
} from '@components/Homepage/OpportunitiesCarousel/CarouselArrowButtons';
import useOpportunitiesCarousel from '@components/Homepage/OpportunitiesCarousel/hooks/useOpportunitiesCarousel';
import { useWindowSize } from '@uidotdev/usehooks';
import OpportunityCard from '@components/Homepage/OpportunitiesCarousel/OpportunityCard';
import { useRouter } from 'next/router';
import { triggerCustomGaEvent } from '@helpers/miscelleanous';
import { homepageGaEventName } from '@helpers/ga-events-name-enum';
import texts from '@components/Homepage/OpportunitiesCarousel/en.json';
import Image from '@atoms/Image';
import { cn } from '@ui/atoms/utils/utils';

const OpportunitiesCarousel = ({
    isHomePage = true,
    signUpModal,
    isLogged,
    opportunities,
    customOnClick,
    title = texts?.dealsTitle,
    className,
}) => {
    const {
        activeSlide,
        emblaRef,
        scrollPrev,
        scrollNext,
    } = useOpportunitiesCarousel({ opportunities });
    const { width } = useWindowSize();
    const router = useRouter();

    const handleOnClick = (opportunityId, canInvest) => {
        if(customOnClick){
            customOnClick(opportunityId, canInvest);
            return;
        }

        if(canInvest) triggerCustomGaEvent(isHomePage ?  homepageGaEventName.CLICKED_INVEST_NOW_DEAL_HOME_PAGE : homepageGaEventName.CLICKED_INVEST_NOW_DEAL_SIGNUP_PAGE);
        else triggerCustomGaEvent(isHomePage ?  homepageGaEventName.CLICKED_VIEW_OPPORTUNITY_DEAL_HOME_PAGE : homepageGaEventName.CLICKED_VIEW_OPPORTUNITY_DEAL_SIGNUP_PAGE);

        if (isLogged) {
            return router.push(`/opportunity/${opportunityId}`);
        }

        const currentPath = router.asPath;
        const redirectUrl = `/opportunity/${opportunityId}`;
        const updatedUrl = `${currentPath}?redirect=${redirectUrl}`;
    
        window.history.replaceState(null, '', updatedUrl);
        router.query.redirect = redirectUrl;
    
        if (isHomePage) {
            signUpModal();
        } else {
            window.scrollTo(0, 0);
        }
    };

    return (
        <div
            className={cn(
                'flex flex-col gap-6 md:gap-10 pb-10 pt-7 md:px-6 max-w-[1120px] mx-auto',
                className
            )}
        >
            <Text
                content={title}
                className="text-center h4-semibold md:h2-semibold text-primary-500"
                isInnerHtml
                htmlTag='h2'
            />
            <div className="relative flex flex-col gap-0 xl:gap-8 embla">
                <PrevButton
                    onClick={scrollPrev}
                    className="hidden xl:inline-block xl:absolute"
                />
                <div
                    className="opportunities_carousel__viewport w-full max-w-[720px] xl:max-w-[856px] md:pb-6 xl:pb-8  overflow-x-hidden mx-auto"
                    ref={emblaRef}
                >
                    <div className="flex items-center gap-16 pb-6 md:pb-0 md:gap-0 opportunities_carousel__container touch-pan-y touch-pinch-zoom xl:gap-0">
                        {opportunities.map((details, index) => (
                            <OpportunityCard
                                key={index}
                                details={details}
                                isActive={activeSlide % opportunities?.length === index}
                                handleOnClick={handleOnClick}
                                index={index}
                                width={width}
                            />
                        ))}
                    </div>
                </div>

                <NextButton
                    onClick={scrollNext}
                    className="hidden xl:inline-block xl:absolute"
                />
                <div className="flex items-center justify-center gap-4 xl:hidden">
                    <PrevButton onClick={scrollPrev} />
                    <NextButton onClick={scrollNext} />
                </div>
            </div>
            <Image
                src="/images/background/purple-skeleton.svg"
                className="absolute w-[104px] md:w-[155px] xl:w-[207px] h-[74px] md:h-[111px] xl:h-[148px] md:-top-[50px] -left-7 md:-left-[40px] -top-6 xl:top-0 xl:-left-3"
            />
        </div>
    );
};

export default OpportunitiesCarousel;